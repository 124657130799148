import styled from "styled-components"
import { FlexContainer } from "./layout.styled"

export const P = styled.h1`
  color: ${({ theme, color }) => theme[color] || theme.lightBlue};
  font-size: ${({ fontSize }) => fontSize || "18px"};
  font-weight: ${({ fontWeight }) => fontWeight || 100};
  margin: ${({ margin }) => margin || 0};
  text-align: center;
`

export const WorkCardContainer = styled(FlexContainer)`
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 14px 120px;
  @media only screen and (max-width: ${({ theme }) => theme.tablet}) {
    padding: 0 14px 40px;
  }
`
