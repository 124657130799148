import React from "react"
import styled, { css } from "styled-components"
import { FlexContainer } from "../../styles/layout.styled"
import { P } from "../../styles/shared.styled"
import { useInView } from "react-intersection-observer"
import { navigate } from "gatsby-link"

const StyledAnimateContainer = styled.div`
  width: 49%;
  ${({ leftSide }) =>
    leftSide
      ? css`
          transform: ${({ inView }) =>
            inView ? "translateX(0)" : "translateX(-440px)"};
          opacity: ${({ inView }) => (inView ? "1" : "0")};
        `
      : css`
          transform: ${({ inView }) =>
            inView ? "translateX(0)" : "translateX(440px)"};
          opacity: ${({ inView }) => (inView ? "1" : "0")};
        `};

  transition: all 1s ease;

  @media only screen and (max-width: ${({ theme }) => theme.tablet}) {
    width: 48%;
    ${({ leftSide }) =>
      leftSide
        ? css`
            transform: ${({ inView }) =>
              inView ? "translateX(0)" : "translateX(-200px)"};
            opacity: ${({ inView }) => (inView ? "1" : "0")};
          `
        : css`
            transform: ${({ inView }) =>
              inView ? "translateX(0)" : "translateX(200px)"};
            opacity: ${({ inView }) => (inView ? "1" : "0")};
          `};
  }

  @media only screen and (max-width: ${({ theme }) => theme.mobile}) {
    width: 100%;
  }
`

const CardContainer = styled(FlexContainer)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: 440px;
  cursor: pointer;
  padding: 0 0 32px;
  background: #12243680 ${({ bgUrl }) => `url(${bgUrl})`} no-repeat;
  background-size: cover;
  transform: scaleX(1);
  transition: all 0.3s ease;
  &:hover {
    transform: scaleX(1.03);
  }
`

const WorkCard = ({ url, title, category, index, id }) => {
  const { ref, inView } = useInView({
    threshold: 0.1,
    triggerOnce: true,
    initialInView: true,
  })

  return (
    <StyledAnimateContainer
      ref={ref}
      inView={inView}
      leftSide={index % 2 === 0}
      onClick={() => navigate(`/our-work/${id}`)}
    >
      <CardContainer bgUrl={url} ref={ref}>
        <P margin="0 0 10px" fontWeight="bold" color="white">
          {title}
        </P>
        <P fontSize="18px" color="white">
          {category}
        </P>
      </CardContainer>
    </StyledAnimateContainer>
  )
}

export default WorkCard
