import React from "react"

//SOCIAL_MEDIA_ICONS
import Instagram from "../assets/svg/instagram.svg"
import Facebook from "../assets/svg/facebook.svg"
import Youtube from "../assets/svg/youtube.svg"

// OUR_SERVICES_ICONS
import VideoIcon from "../assets/svg/video-camera.svg"
import GraphicsIcon from "../assets/svg/motion-graphics.svg"
import CameraIcon from "../assets/svg/photo-camera.svg"
import FluentIcon from "../assets/svg/fluent_design.svg"

// WORK_IMAGES
import Rectangle from "../assets/images/Rectangle.png"
import Rectangle2 from "../assets/images/Rectangle2.png"
import Rectangle3 from "../assets/images/Rectangle3.png"
import Rectangle4 from "../assets/images/Rectangle4.png"

import AllProjects from "../components/OurWork/AllProjects"
import VideoProduction from "../components/OurWork/VideoProduction"

export const SOCIAL_MEDIA_ICON = [
  {
    id: 1,
    icon: <Instagram />,
    href: "https://www.instagram.com/megproduction_am/?utm_medium=copy_link",
  },
  {
    id: 2,
    icon: <Facebook />,
    href: "https://www.facebook.com/MEGProductionOfficial/",
  },
  {
    id: 3,
    icon: <Youtube />,
    href: "https://www.youtube.com/channel/UCywF2n6ikxP6w7lUx-u0kIg",
  },
]

export const NAV_ITEMS = [
  { id: 0, location: "/", name: "HOME" },
  { id: 1, location: "/about-us/", name: "ABOUT US" },
  { id: 2, location: "/our-work/", name: "OUR WORK" },
  { id: 3, location: "/blog/", name: "BLOG" },
  { id: 4, location: "/contact/", name: "CONTACT" },
]

export const OUR_SERVICES = [
  {
    id: 0,
    location: "/our-work/?project=video-production",
    title: "Video Production",
    description:
      "The experienced shooting team of MEG Production is constantly improving its knowledge, and we use the best professional equipment for shooting.",
    icon: <VideoIcon />,
  },
  {
    id: 1,
    location: "/our-work/?project=2d-3d-animation",
    title: "2D/3D Animation",
    description:
      "Our talented 2D & 3D animation staff pay special attention to each job to accomplish highly specific business goals.",
    icon: <GraphicsIcon />,
  },
  {
    id: 2,
    location: "/our-work/?project=commercial-photography",
    title: "Commercial Photography",
    description:
      "Our photographers, in keeping up with times, study and test the latest photography equipment, as well as the various lights offered on the market. As a result, they know how to photograph your product or event to get the best photos.",
    icon: <CameraIcon />,
  },
  {
    id: 3,
    location: "/our-work/?project=graphic-design",
    title: "Graphic Design",
    description:
      "As a result of the work of our skilled graphic designers, your company will be presented in the market with a unique, original, creative and attractive design.",
    icon: <FluentIcon />,
  },
]

export const WORK = [
  {
    id: 0,
    url: Rectangle,
    title: "TITLE OF THE PROJECT",
    category: "CATEGORY",
  },
  {
    id: 1,
    url: Rectangle2,
    title: "TITLE OF THE PROJECT",
    category: "CATEGORY",
  },
  {
    id: 2,
    url: Rectangle3,
    title: "TITLE OF THE PROJECT",
    category: "CATEGORY",
  },
  {
    id: 3,
    url: Rectangle4,
    title: "TITLE OF THE PROJECT",
    category: "CATEGORY",
  },
]

export const OUR_WORK_TABS = [
  { id: 0, name: "ALL PROJECTS", location: "all-projects" },
  { id: 1, name: "VIDEO PRODUCTION", location: "video-production" },
  { id: 2, name: "2D/3D ANIMATION", location: "2d-3d-animation" },
  { id: 3, name: "COMMERCIAL PHOTOGRAPHY", location: "commercial-photography" },
  { id: 4, name: "GRAPHIC DESIGN", location: "graphic-design" },
]

export const OUR_WORK_NODES = [
  { id: 0, Node: AllProjects, location: "all-projects" },
  {
    id: 1,
    Node: VideoProduction,
    location: "video-production",
  },
  {
    id: 2,
    Node: AllProjects,
    location: "2d-3d-animation",
  },
  {
    id: 3,
    Node: AllProjects,
    location: "commercial-photography",
  },
  {
    id: 4,
    Node: AllProjects,
    location: "graphic-design",
  },
]

export const YEAR = +new Date(Date.now()).getFullYear() - 2020

export const COLUMNS_LOCATION = [5, 15, 25, 35, 45, 55, 65, 75, 85, 95]

export const HEADER_BG = "rgba(18, 36, 54, 0.6)"
