import React from "react"
import { WORK } from "../../constants"
import WorkCard from "../reusable/WorkCard"
import { WorkCardContainer } from "../../styles/shared.styled"

const AllProjects = () => {
  return (
    <>
      <WorkCardContainer>
        {WORK.map(({ id, title, category, url }, index) => (
          <WorkCard
            key={id}
            title={title}
            category={category}
            url={url}
            index={index}
          />
        ))}
      </WorkCardContainer>
    </>
  )
}

export default AllProjects
