import styled from "styled-components"

export const FlexContainer = styled.div`
  display: flex;
  position: relative;
  background: ${({ bg }) => bg};
  width: ${({ width }) => width || "100%"};
  max-width: ${({ maxWidth }) => maxWidth};
  flex-direction: ${({ flexDirection }) => flexDirection || "row"};
  justify-content: ${({ justifyContent }) => justifyContent || "center"};
  align-items: ${({ alignItems }) => alignItems || "center"};
  flex-wrap: ${({ flexWrap }) => (flexWrap ? "wrap" : "nowrap")};
  flex-grow: ${({ flexGrow }) => (flexGrow ? 1 : "initial")};
  padding: ${({ padding }) => padding};
  margin: ${({ margin }) => margin};
  cursor: ${({ cursor }) => cursor};
  @media only screen and (max-width: ${({ theme }) => theme.tablet}) {
    width: ${({ tabletWidth }) => tabletWidth};
    flex-direction: ${({ tabletFlexDirection }) => tabletFlexDirection};
    justify-content: ${({ tabletJustifyContent }) => tabletJustifyContent};
    align-items: ${({ tabletAlignItems }) => tabletAlignItems};
    flex-wrap: ${({ tabletFlexWrap }) => (tabletFlexWrap ? "wrap" : "nowrap")};
    flex-grow: ${({ tabletFlexGrow }) => (tabletFlexGrow ? 1 : "initial")};
    padding: ${({ tabletPadding }) => tabletPadding};
    margin: ${({ tabletMargin }) => tabletMargin};
  }
  @media only screen and (max-width: ${({ theme }) => theme.mobile}) {
    width: ${({ mobileWidth }) => mobileWidth};
    flex-direction: ${({ mobileFlexDirection }) => mobileFlexDirection};
    justify-content: ${({ mobileJustifyContent }) => mobileJustifyContent};
    align-items: ${({ mobileAlignItems }) => mobileAlignItems};
    padding: ${({ mobilePadding }) => mobilePadding};
    margin: ${({ mobileMargin }) => mobileMargin};
  }
`

export const FlexItem = styled.div`
  flex: ${({ flex }) => flex};
  margin: ${({ margin }) => margin};
  padding: ${({ padding }) => padding};
  width: ${({ width }) => width};
`
