import React from "react"
import { WorkCardContainer } from "../../styles/shared.styled"
import WorkCard from "../reusable/WorkCard"
import { graphql, useStaticQuery } from "gatsby"

const VideoProduction = () => {
  const {
    videoProduction: { nodes },
  } = useStaticQuery(graphql`
    {
      videoProduction: allContentfulOurWork(
        filter: { workCategory: { eq: "video production" } }
      ) {
        nodes {
          workTitle
          id
          workCategory
          description
          workCover {
            file {
              url
            }
          }
        }
      }
    }
  `)

  return (
    <WorkCardContainer>
      {nodes.map(
        (
          {
            id,
            workTitle,
            workCategory,
            workCover: {
              file: { url },
            },
          },
          index
        ) => (
          <WorkCard
            id={id}
            key={id}
            title={workTitle}
            category={workCategory}
            url={url}
            index={index}
          />
        )
      )}
    </WorkCardContainer>
  )
}

export default VideoProduction
